import { subscribeKey as subKey } from 'valtio/vanilla/utils';
import { proxy, ref, subscribe as sub } from 'valtio/vanilla';
import { CoreHelperUtil } from '../utils/CoreHelperUtil.js';
import { BlockchainApiController } from './BlockchainApiController.js';
import { SnackController } from './SnackController.js';
import { SwapController } from './SwapController.js';
import { SwapApiUtil } from '../utils/SwapApiUtil.js';
import { NetworkController } from './NetworkController.js';
const state = proxy({
  isConnected: false,
  currentTab: 0,
  tokenBalance: [],
  smartAccountDeployed: false
});
export const AccountController = {
  state,
  subscribe(callback) {
    return sub(state, () => callback(state));
  },
  subscribeKey(key, callback) {
    return subKey(state, key, callback);
  },
  setIsConnected(isConnected) {
    state.isConnected = isConnected;
  },
  setCaipAddress(caipAddress) {
    state.caipAddress = caipAddress;
    state.address = caipAddress ? CoreHelperUtil.getPlainAddress(caipAddress) : undefined;
  },
  setBalance(balance, balanceSymbol) {
    state.balance = balance;
    state.balanceSymbol = balanceSymbol;
  },
  setProfileName(profileName) {
    state.profileName = profileName;
  },
  setProfileImage(profileImage) {
    state.profileImage = profileImage;
  },
  setAddressExplorerUrl(explorerUrl) {
    state.addressExplorerUrl = explorerUrl;
  },
  setSmartAccountDeployed(isDeployed) {
    state.smartAccountDeployed = isDeployed;
  },
  setCurrentTab(currentTab) {
    state.currentTab = currentTab;
  },
  setTokenBalance(tokenBalance) {
    if (tokenBalance) {
      state.tokenBalance = ref(tokenBalance);
    }
  },
  setConnectedWalletInfo(connectedWalletInfo) {
    state.connectedWalletInfo = connectedWalletInfo;
  },
  setPreferredAccountType(preferredAccountType) {
    state.preferredAccountType = preferredAccountType;
  },
  setSocialProvider(socialProvider) {
    if (socialProvider) {
      state.socialProvider = socialProvider;
    }
  },
  setSocialWindow(socialWindow) {
    if (socialWindow) {
      state.socialWindow = ref(socialWindow);
    }
  },
  async fetchTokenBalance() {
    const chainId = NetworkController.state.caipNetwork?.id;
    try {
      if (state.address && chainId) {
        const response = await BlockchainApiController.getBalance(state.address, chainId);
        const filteredBalances = response.balances.filter(balance => balance.quantity.decimals !== '0');
        this.setTokenBalance(filteredBalances);
        SwapController.setBalances(SwapApiUtil.mapBalancesToSwapTokens(response.balances));
      }
    } catch (error) {
      SnackController.showError('Failed to fetch token balance');
    }
  },
  resetAccount() {
    state.isConnected = false;
    state.smartAccountDeployed = false;
    state.currentTab = 0;
    state.caipAddress = undefined;
    state.address = undefined;
    state.balance = undefined;
    state.balanceSymbol = undefined;
    state.profileName = undefined;
    state.profileImage = undefined;
    state.addressExplorerUrl = undefined;
    state.tokenBalance = [];
    state.connectedWalletInfo = undefined;
    state.preferredAccountType = undefined;
    state.socialProvider = undefined;
    state.socialWindow = undefined;
  }
};
