import { css } from 'lit';
export default css`
  :host {
    display: block;
  }

  button {
    width: 100%;
    display: block;
    padding-top: var(--wui-spacing-l);
    padding-bottom: var(--wui-spacing-l);
    padding-left: var(--wui-spacing-s);
    padding-right: var(--wui-spacing-2l);
    border-radius: var(--wui-border-radius-s);
    background-color: var(--wui-color-accent-glass-015);
  }

  button:hover {
    background-color: var(--wui-color-accent-glass-010) !important;
  }

  button:active {
    background-color: var(--wui-color-accent-glass-020) !important;
  }
`;
