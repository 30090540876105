import { subscribeKey as subKey } from 'valtio/vanilla/utils';
import { proxy, subscribe as sub } from 'valtio/vanilla';
const state = proxy({
  walletImages: {},
  networkImages: {},
  connectorImages: {},
  tokenImages: {},
  currencyImages: {}
});
export const AssetController = {
  state,
  subscribeNetworkImages(callback) {
    return sub(state.networkImages, () => callback(state.networkImages));
  },
  subscribeKey(key, callback) {
    return subKey(state, key, callback);
  },
  subscribe(callback) {
    return sub(state, () => callback(state));
  },
  setWalletImage(key, value) {
    state.walletImages[key] = value;
  },
  setNetworkImage(key, value) {
    state.networkImages[key] = value;
  },
  setConnectorImage(key, value) {
    state.connectorImages[key] = value;
  },
  setTokenImage(key, value) {
    state.tokenImages[key] = value;
  },
  setCurrencyImage(key, value) {
    state.currencyImages[key] = value;
  }
};
