export const InputUtil = {
  numericInputKeyDown(event, currentValue, onChange) {
    const allowedKeys = ['Backspace', 'Meta', 'Ctrl', 'a', 'A', 'c', 'C', 'x', 'X', 'v', 'V', 'ArrowLeft', 'ArrowRight', 'Tab'];
    const controlPressed = event.metaKey || event.ctrlKey;
    const selectAll = event.key === 'a' || event.key === 'A';
    const copyKey = event.key === 'c' || event.key === 'C';
    const pasteKey = event.key === 'v' || event.key === 'V';
    const cutKey = event.key === 'x' || event.key === 'X';
    const isComma = event.key === ',';
    const isDot = event.key === '.';
    const isNumericKey = event.key >= '0' && event.key <= '9';
    if (!controlPressed && (selectAll || copyKey || pasteKey || cutKey)) {
      event.preventDefault();
    }
    if (currentValue === '0' && !isComma && !isDot && event.key === '0') {
      event.preventDefault();
    }
    if (currentValue === '0' && isNumericKey) {
      onChange(event.key);
      event.preventDefault();
    }
    if (isComma || isDot) {
      if (!currentValue) {
        onChange('0.');
        event.preventDefault();
      }
      if (currentValue?.includes('.') || currentValue?.includes(',')) {
        event.preventDefault();
      }
    }
    if (!isNumericKey && !allowedKeys.includes(event.key) && !isDot && !isComma) {
      event.preventDefault();
    }
  }
};
