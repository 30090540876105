import { subscribeKey as subKey } from 'valtio/vanilla/utils';
import { proxy, subscribe as sub } from 'valtio/vanilla';
const state = proxy({
  message: '',
  open: false,
  triggerRect: {
    width: 0,
    height: 0,
    top: 0,
    left: 0
  },
  variant: 'shade'
});
export const TooltipController = {
  state,
  subscribe(callback) {
    return sub(state, () => callback(state));
  },
  subscribeKey(key, callback) {
    return subKey(state, key, callback);
  },
  showTooltip({
    message,
    triggerRect,
    variant
  }) {
    state.open = true;
    state.message = message;
    state.triggerRect = triggerRect;
    state.variant = variant;
  },
  hide() {
    state.open = false;
    state.message = '';
    state.triggerRect = {
      width: 0,
      height: 0,
      top: 0,
      left: 0
    };
  }
};
