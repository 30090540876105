export function getW3mThemeVariables(themeVariables, themeType) {
  if (themeType === 'light') {
    return {
      '--w3m-accent': themeVariables?.['--w3m-accent'] || 'hsla(231, 100%, 70%, 1)',
      '--w3m-background': '#fff'
    };
  }
  return {
    '--w3m-accent': themeVariables?.['--w3m-accent'] || 'hsla(230, 100%, 67%, 1)',
    '--w3m-background': '#121313'
  };
}
