import { subscribeKey as subKey } from 'valtio/vanilla/utils';
import { proxy, subscribe as sub } from 'valtio/vanilla';
import { ONRAMP_PROVIDERS } from '../utils/ConstantsUtil.js';
import { BlockchainApiController } from './BlockchainApiController.js';
import { ApiController } from './ApiController.js';
export const USDC_CURRENCY_DEFAULT = {
  id: '2b92315d-eab7-5bef-84fa-089a131333f5',
  name: 'USD Coin',
  symbol: 'USDC',
  networks: [{
    name: 'ethereum-mainnet',
    display_name: 'Ethereum',
    chain_id: '1',
    contract_address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
  }, {
    name: 'polygon-mainnet',
    display_name: 'Polygon',
    chain_id: '137',
    contract_address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'
  }]
};
export const USD_CURRENCY_DEFAULT = {
  id: 'USD',
  payment_method_limits: [{
    id: 'card',
    min: '10.00',
    max: '7500.00'
  }, {
    id: 'ach_bank_account',
    min: '10.00',
    max: '25000.00'
  }]
};
const defaultState = {
  providers: ONRAMP_PROVIDERS,
  selectedProvider: null,
  error: null,
  purchaseCurrency: USDC_CURRENCY_DEFAULT,
  paymentCurrency: USD_CURRENCY_DEFAULT,
  purchaseCurrencies: [USDC_CURRENCY_DEFAULT],
  paymentCurrencies: [],
  quotesLoading: false
};
const state = proxy(defaultState);
export const OnRampController = {
  state,
  subscribe(callback) {
    return sub(state, () => callback(state));
  },
  subscribeKey(key, callback) {
    return subKey(state, key, callback);
  },
  setSelectedProvider(provider) {
    state.selectedProvider = provider;
  },
  setPurchaseCurrency(currency) {
    state.purchaseCurrency = currency;
  },
  setPaymentCurrency(currency) {
    state.paymentCurrency = currency;
  },
  setPurchaseAmount(amount) {
    this.state.purchaseAmount = amount;
  },
  setPaymentAmount(amount) {
    this.state.paymentAmount = amount;
  },
  async getAvailableCurrencies() {
    const options = await BlockchainApiController.getOnrampOptions();
    state.purchaseCurrencies = options.purchaseCurrencies;
    state.paymentCurrencies = options.paymentCurrencies;
    state.paymentCurrency = options.paymentCurrencies[0] || USD_CURRENCY_DEFAULT;
    state.purchaseCurrency = options.purchaseCurrencies[0] || USDC_CURRENCY_DEFAULT;
    await ApiController.fetchCurrencyImages(options.paymentCurrencies.map(currency => currency.id));
    await ApiController.fetchTokenImages(options.purchaseCurrencies.map(currency => currency.symbol));
  },
  async getQuote() {
    state.quotesLoading = true;
    try {
      const quote = await BlockchainApiController.getOnrampQuote({
        purchaseCurrency: state.purchaseCurrency,
        paymentCurrency: state.paymentCurrency,
        amount: state.paymentAmount?.toString() || '0',
        network: state.purchaseCurrency?.symbol
      });
      state.quotesLoading = false;
      state.purchaseAmount = Number(quote.purchaseAmount.amount);
      return quote;
    } catch (error) {
      state.error = error.message;
      state.quotesLoading = false;
      return null;
    } finally {
      state.quotesLoading = false;
    }
  },
  resetState() {
    state.providers = ONRAMP_PROVIDERS;
    state.selectedProvider = null;
    state.error = null;
    state.purchaseCurrency = USDC_CURRENCY_DEFAULT;
    state.paymentCurrency = USD_CURRENCY_DEFAULT;
    state.purchaseCurrencies = [USDC_CURRENCY_DEFAULT];
    state.paymentCurrencies = [];
    state.paymentAmount = undefined;
    state.purchaseAmount = undefined;
    state.quotesLoading = false;
  }
};
