var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import '../../components/wui-icon/index.js';
import { elementStyles, resetStyles } from '../../utils/ThemeUtil.js';
import { customElement } from '../../utils/WebComponentsUtil.js';
import styles from './styles.js';
let WuiIconBox = class WuiIconBox extends LitElement {
  constructor() {
    super(...arguments);
    this.size = 'md';
    this.backgroundColor = 'accent-100';
    this.iconColor = 'accent-100';
    this.background = 'transparent';
    this.border = false;
    this.borderColor = 'wui-color-bg-125';
    this.icon = 'copy';
  }
  render() {
    const iconSize = this.iconSize || this.size;
    const isLg = this.size === 'lg';
    const isXl = this.size === 'xl';
    const bgMix = isLg ? '12%' : '16%';
    const borderRadius = isLg ? 'xxs' : isXl ? 's' : '3xl';
    const isGray = this.background === 'gray';
    const isOpaque = this.background === 'opaque';
    const isColorChange = this.backgroundColor === 'accent-100' && isOpaque || this.backgroundColor === 'success-100' && isOpaque || this.backgroundColor === 'error-100' && isOpaque || this.backgroundColor === 'inverse-100' && isOpaque;
    let bgValueVariable = `var(--wui-color-${this.backgroundColor})`;
    if (isColorChange) {
      bgValueVariable = `var(--wui-icon-box-bg-${this.backgroundColor})`;
    } else if (isGray) {
      bgValueVariable = `var(--wui-gray-${this.backgroundColor})`;
    }
    this.style.cssText = `
       --local-bg-value: ${bgValueVariable};
       --local-bg-mix: ${isColorChange || isGray ? `100%` : bgMix};
       --local-border-radius: var(--wui-border-radius-${borderRadius});
       --local-size: var(--wui-icon-box-size-${this.size});
       --local-border: ${this.borderColor === 'wui-color-bg-125' ? `2px` : `1px`} solid ${this.border ? `var(--${this.borderColor})` : `transparent`}
   `;
    return html` <wui-icon color=${this.iconColor} size=${iconSize} name=${this.icon}></wui-icon> `;
  }
};
WuiIconBox.styles = [resetStyles, elementStyles, styles];
__decorate([property()], WuiIconBox.prototype, "size", void 0);
__decorate([property()], WuiIconBox.prototype, "backgroundColor", void 0);
__decorate([property()], WuiIconBox.prototype, "iconColor", void 0);
__decorate([property()], WuiIconBox.prototype, "iconSize", void 0);
__decorate([property()], WuiIconBox.prototype, "background", void 0);
__decorate([property({
  type: Boolean
})], WuiIconBox.prototype, "border", void 0);
__decorate([property()], WuiIconBox.prototype, "borderColor", void 0);
__decorate([property()], WuiIconBox.prototype, "icon", void 0);
WuiIconBox = __decorate([customElement('wui-icon-box')], WuiIconBox);
export { WuiIconBox };
